import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { domain, clientId, audience } from "../auth_config.json";
import { Auth0Plugin } from "./auth/auth0-plugin";
import Axios from "axios";
// eslint-disable-next-line no-unused-vars
// axios.baseURL = 'http://localhost:3000'
Vue.config.productionTip = false;
//let axios = Axios.create({ baseURL: "http://localhost:3000" });
let axios = Axios.create({ baseURL: "https://pop-word.com" });

// Install the authentication plugin
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    console.log(appState);
    console.log(window.location.pathname);

    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

const MyApp = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount("#app");

axios.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    let access_token = await MyApp.$auth.getTokenSilently();
    config.headers.Authorization = `Bearer ${access_token}`;
    config.params = { ...config.params, email: MyApp.$auth.user.email };

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
export { axios, MyApp };
