<template>
  <div>
    <v-navigation-drawer width="150" :value="sidebar" v-model="sidebar" app temporary>
      <v-list class="d-flex flex-column px-2">
        <v-btn
            v-for="item in routes"
            :key="item.href"
            :href="item.href"
            v-on:click="sidebar=false"
            elevation="0"
            text
            plain

            class="my-2"
            :color="item.href=='#pricing' ?'primary':'' ">


          {{ translations[item.title][selected_website_language] }}
          <!--          <v-list-item-content color="">{{ item.title }}</v-list-item-content>-->
        </v-btn>
        <v-select
            :items="website_languages"
            label="Website language"
            v-model="selected_website_language"
            outlined
            dense
            flat

            hide-details
            @change="reloadWebsite(selected_website_language)"
        ></v-select>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar elevation="0">

      <v-app-bar-nav-icon>
        <span class="d-md-none">
          <v-icon v-on:click="sidebar=!sidebar">fas fa-bars</v-icon>
        </span>
        <v-img class="d-none d-md-flex " width="30" src="@/assets/popword_icon.svg"></v-img>
      </v-app-bar-nav-icon>
      <v-app-bar-title class="primary--text  text-h5">
        PoPWord
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <div class="d-none d-md-flex">
        <v-btn
            class="mx-2"
            elevation="0"
            text
            plain

            v-for="item in routes[selected_website_language]"
            :key="item.href"
            :href="item.href"
            :color="item.href=='#pricing' ?'primary':'' ">

          {{ translations[item.title][selected_website_language] }}
        </v-btn>
        <v-select
            :items="website_languages"
            label="Website language"
            v-model="selected_website_language"
            outlined
            dense
            flat
            tile
            hide-details
            @change="reloadWebsite(selected_website_language)"
        ></v-select>
      </div>

    </v-app-bar>
    <div class="bordered" style="margin-top: 1px">
      <div class="text-center">
        <v-sheet elevation="0" height="100%" width="100%" :color="this.$vuetify.theme.themes.light.background">
          {{ translations.popword_activated_message[selected_website_language] }}
        </v-sheet>
      </div>
      <div class="demo_banner d-flex overflow-x-auto  ">
        <v-template style="flex-grow: 1;" v-for="lang in languages" :key="lang.abbr">
          <v-btn
              v-if="selected_language==lang.abbr"
              class=" bordered no-right-border no-bottom-border selectedLanguage"
              @click="selected_language=lang.abbr"
              plain tile>
            <v-img width="23" height="23" class="rounded-circle mr-2"
                   :src="`http://purecatamphetamine.github.io/country-flag-icons/3x2/${lang.country.toUpperCase()}.svg`"></v-img>
            {{ lang.lang }}
          </v-btn>
          <v-btn
              v-else class="bordered no-right-border no-bottom-border" @click="selected_language=lang.abbr"
              plain tile>
            <v-img width="23" height="23" class="rounded-circle mr-2"
                   :src="`http://purecatamphetamine.github.io/country-flag-icons/3x2/${lang.country.toUpperCase()}.svg`"></v-img>
            {{ lang.lang }}
          </v-btn>
        </v-template>
      </div>
    </div>
    <v-container class="bordered no-top-border px-0 pb-0 " style="padding-top: 100px !important;}">

      <div class="pa-4">
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" md="6" sm="12" class="text-left">
            <p class="text-h2" v-html="translations.slogan[selected_website_language]"></p>
            <p v-html="translations.short_description[selected_website_language]"></p>
            <v-btn tile color="primary" large elevation="0" href="#pricing">Get it Now</v-btn>
          </v-col>
          <v-col cols="12" md="6" sm="12" class="d-flex" style="height:359px">

            <iframe class="flex-grow-1" src="https://youtube.com/embed/4DmH_02h758"
                    style="border-radius: 0px"></iframe>


          </v-col>
        </v-row>
      </div>
      <div id="features" class="my-6">
        <h3 class="text-h2">
            <span
                class="word_wrapper FeaturesExtension pa-4"> {{ translations.features[selected_website_language]
              }}</span>
        </h3>
        <v-row class="d-flex mt-4 py-10 px-4 justify-center align-center bordered-top feat">
          <v-col cols="12" md="6" sm="12">
            <v-card flat tile>

              <v-card-text>
                <lottie-player style="max-width:600px;" class="mx-auto"
                               src="https://pop-word.com/animations/words & sentences.json" background="transparent"
                               speed="1" loop autoplay></lottie-player>
              </v-card-text>

            </v-card>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <h3 class="text-h6" v-html="translations.feature1[selected_website_language]"></h3>
            <v-card-subtitle class="pt-2"
                             v-html="translations.feature1_description[selected_website_language]"></v-card-subtitle>
          </v-col>

        </v-row>
        <v-row class="d-flex py-10 px-4 justify-center align-center bordered-top feat">
          <v-col cols="12" md="6" sm="12">
            <h3 class="text-h6" v-html="translations.feature2[selected_website_language]"></h3>
            <v-card-subtitle class="pt-2"
                             v-html="translations.feature2_description[selected_website_language]"></v-card-subtitle>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-card flat tile>

              <v-card-text>
                <lottie-player style="max-width:600px;" class="mx-auto"
                               src="https://pop-word.com/animations/youtube.json"
                               background="transparent" speed="1" loop autoplay></lottie-player>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="d-flex py-10 px-4 justify-center align-center bordered-top bordered-bottom feat">
          <v-col cols="12" md="6" sm="12">
            <v-card flat tile>

              <v-card-text>
                <lottie-player style="max-width:600px;" class="mx-auto"
                               src="https://pop-word.com/animations/vocab practice.json" background="transparent"
                               speed="1"
                               loop autoplay></lottie-player>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <h3 class="text-h6" v-html="translations.feature3[selected_website_language]"></h3>
            <v-card-subtitle class="pt-2"
                             v-html="translations.feature3_description[selected_website_language]"></v-card-subtitle>
          </v-col>
        </v-row>
      </div>

      <div id="how_to_install my-6">
        <h3 class="text-h2 pa-4" v-html="translations.install[selected_website_language]">
          <span class="word_wrapper HowExtension"> How</span>
          <span class="word_wrapper toExtension"> to</span>
          <span class="word_wrapper installExtension"> install</span>
        </h3>
        <div>
          <v-stepper class="rounded-0 bordered-bottom custom_background" non-linear flat tile elevation="0"
                     v-model="e1">
            <v-stepper-header>
              <v-stepper-step
                  editable
                  step="1"

              >
                {{ translations.install_step1[selected_website_language] }}
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                  editable
                  step="2"
              >
                {{ translations.install_step2[selected_website_language] }}
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                  step="3"
                  editable
              >
                {{ translations.install_step3[selected_website_language] }}
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content key="1-content" step="1">
                <v-card class="custom_background" flat>
                  {{ translations.install_step1_description[selected_website_language] }}
                </v-card>
              </v-stepper-content>
              <v-stepper-content key="2-content" step="2">
                <v-card flat class="custom_background">
                  {{ translations.install_step2_description[selected_website_language] }}
                </v-card>
                <v-btn color="primary"
                       elevation="0"
                       tile
                       class="bordered"
                       href="https://chrome.google.com/webstore/detail/popword-define-and-transl/aajjppjocelfgbnnfpcifcoklenljjmh"
                       target="_blank">
                  Install
                </v-btn>
              </v-stepper-content>
              <v-stepper-content key="3-content" step="3">
                <v-card flat class="custom_background">
                  {{ translations.install_step3_description[selected_website_language] }}
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>


        </div>

      </div>
      <div class="my-6 pa-4 bordered-bottom">
        <h3 class="text-h2 " v-html="translations.testimonials[selected_website_language]"></h3>
        <template>

          <v-row align="center">
            <v-item-group
                v-model="window"
                class="shrink mr-6"
                mandatory
                tag="v-flex"
            >
              <v-item
                  v-for="n in 3"
                  :key="n"
                  v-slot="{ active, toggle }"
              >
                <div>
                  <v-btn
                      :input-value="active"
                      icon tile x-small
                      @click="toggle"
                  >
                    <v-icon>fas fa-square</v-icon>
                  </v-btn>
                </div>
              </v-item>
            </v-item-group>

            <v-col>
              <v-window
                  v-model="window"
                  class="elevation-0"
                  vertical
              >
                <v-window-item>
                  <v-card flat class="custom_background">
                    <v-card-title>alexis_llosu15</v-card-title>
                    <v-card-subtitle class="py-0">
                      <v-icon v-for="i in [1,2,3,4,5]" :key="i" small color="primary">fas fa-star</v-icon>
                    </v-card-subtitle>
                    <blockquote class="blockquote py-2"
                                v-html="translations.testimonial1[selected_website_language]"></blockquote>
                  </v-card>
                </v-window-item>
                <v-window-item>
                  <v-card flat class="custom_background">
                    <v-card-title>Oliver Belle</v-card-title>
                    <v-card-subtitle class="py-0">
                      <v-icon v-for="i in [1,2,3,4,5]" :key="i" small color="primary">fas fa-star</v-icon>
                    </v-card-subtitle>
                    <blockquote class="blockquote py-2"
                                v-html="translations.testimonial2[selected_website_language]"></blockquote>
                  </v-card>
                </v-window-item>
                <v-window-item>
                  <v-card flat class="custom_background">
                    <v-card-title></v-card-title>
                    <v-card-subtitle class="py-0">
                      <v-icon v-for="i in [1,2,3,4,5]" :key="i" small color="primary">fas fa-star</v-icon>
                    </v-card-subtitle>
                    <blockquote class="blockquote py-2"
                                v-html="translations.testimonial3[selected_website_language]"></blockquote>
                  </v-card>
                </v-window-item>
              </v-window>
            </v-col>
          </v-row>

        </template>

      </div>


      <div id="pricing" class="d-flex justify-center my-6">
        <h3 class="text-h2 primary--text text-center" v-html="translations.startnow[selected_website_language]"></h3>
      </div>
      <v-row class="bordered-top" no-gutters>
        <v-col cols="12" md="4" sm="12">
          <v-card flat rounded="0" class="d-flex flex-column fill-height bordered-right custom_background">
            <v-card-title class="mx-auto text-h3" style="width: fit-content"
                          v-html="translations.free[selected_website_language]"></v-card-title>

            <v-spacer></v-spacer>
            <v-card-text>
              <v-list tile class="custom_background">

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature1[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature2[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature3[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature4[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature5[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature6[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>

                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature7[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-times</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature8[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-times</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature9[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>

              </v-list>
            </v-card-text>

            <v-card-actions class="mb-4">
              <v-btn
                  tile large block elevation="0"
                  href="https://chrome.google.com/webstore/detail/popword-define-and-transl/aajjppjocelfgbnnfpcifcoklenljjmh"
                  target="_blank"
                  class="mx-auto"
                  color="primary">
                Subscribe
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <v-card flat rounded="0" class="d-flex flex-column fill-height bordered-right custom_background">
            <v-card-title class="mx-auto text-h3" style="width: fit-content">
              <div class="word_wrapper 3$Extension"> 3$</div>

            </v-card-title>
            <v-card-subtitle class="mx-auto " style="width: fit-content"
                             v-html="translations.permonth[selected_website_language]"></v-card-subtitle>
            <v-spacer></v-spacer>
            <v-card-text>
              <v-list tile class="custom_background">

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature1[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature2[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature3[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature4[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature5[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature6[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>

                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature7[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature8[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature9[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>

              </v-list>
            </v-card-text>

            <v-card-actions class="mb-4">
              <v-btn
                  tile large elevation="0"
                  block
                  class="mx-auto paddle_button"
                  data-theme="none"
                  data-product="793946"
                  data-success="https://pop-word.com/thankyou"
                  color="primary"
                  onclick="gtag_report_conversion_monthly('https://pop-word.com')">
                Subscribe
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <v-card flat rounded="0" class="d-flex flex-column custom_background">
            <v-chip
                color="primary"
                label
                class="rounded-0"
                style="font-size: 16px;position:absolute;left: 5px;top:5px;"
            >
              {{translations["most popular"][selected_website_language]}}
            </v-chip>
            <v-card-title class="mx-auto text-h3" style="width: fit-content">
              <span class="word_wrapper 30$Extension "> 30$</span>

            </v-card-title>
            <v-card-subtitle class="mx-auto" style="width: fit-content"
                             v-html="translations.peryear[selected_website_language]">
            </v-card-subtitle>
            <v-card-subtitle class="mx-auto d-flex flex-column" style="width: fit-content">
              <v-chip
                  color="secondary"
                  outlined
                  style="font-size: 14px"
              >
                {{ translations.annual_plan_message[selected_website_language] }}
              </v-chip>
            </v-card-subtitle>
            <v-card-text>
              <v-list tile class="custom_background">

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature1[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature2[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature3[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature4[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature5[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature6[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>

                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature7[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature8[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" small>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <div class="text-body-1" v-html="translations.plan_feature9[selected_website_language]"></div>
                  </v-list-item-content>
                </v-list-item>

              </v-list>
            </v-card-text>
            <v-card-actions class="mb-4">
              <v-btn
                  tile large elevation="0"
                  block
                  class="mx-auto paddle_button"
                  data-theme="none"
                  color="primary"
                  data-product="636845"
                  data-success="https://pop-word.com/thankyou"
                  onclick="gtag_report_conversion_yearly('https://pop-word.com')"
              >

                Subscribe
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>


    </v-container>


    <v-footer
        id="contact"
        padless
    >
      <v-row
          justify="center"
          no-gutters
      >
        <v-btn
            v-for="link in links[selected_website_language]"
            :key="link.title"
            text
            link
            plain
            :href="link.href"
            class="my-2"
        >
          {{ link.title }}
        </v-btn>
        <v-col
            class="py-4 text-center"
            cols="12"
        >
          {{ new Date().getFullYear() }} — <strong>PoPWord</strong>
        </v-col>
      </v-row>
    </v-footer>

  </div>
  <!--  </div>-->
</template>

<script>
import translations from "../assets/translations.js";

export default {
  name: "LandingPage",
  data() {
    return {
      translations,
      sidebar: false,
      window: 0,
      routes: [
        { title: "get it now", href: "#pricing" },

        { title: "features", href: "#features" },
        { title: "contact", href: "#contact" },
        {

          title: "blog",
          href: "https://pop-word.com/articles"
        },
        {

          title: "vocab Lobby",
          href: "https://pop-word.com/ui/#/Login"
        }
      ],
      selected_language: "fr",
      website_languages: [{ text: "german", value: "de" }, { text: "spanish", value: "es" }, {
        text: "french",
        value: "fr"
      }, { text: "english", value: "en" }],
      website_languages_abbr: ["de", "es", "fr", "en"],
      selected_website_language: "en",
      languages: [
        {
          lang: "Arabic", abbr: "ar", country: "sa"
        },
        {
          lang: "Dutch", abbr: "nl", country: "nl"
        },
        {
          lang: "French", abbr: "fr", country: "fr"
        },
        {
          lang: "German", abbr: "de", country: "de"
        },
        {
          lang: "Italian", abbr: "it", country: "it"
        }, {
          lang: "English", abbr: "en", country: "us"
        },
        {
          lang: "Japanese", abbr: "ja", country: "jp"
        },
        {
          lang: "Russian", abbr: "ru", country: "ru"
        },
        {
          lang: "Spanish", abbr: "es", country: "es"
        },
        {
          lang: "Danish", abbr: "da", country: "dk"
        },
        {
          lang: "Korean", abbr: "ko", country: "kr"
        },
        {
          lang: "Portuguese", abbr: "pt", country: "pt"
        },
        {
          lang: "Turkish", abbr: "tr", country: "tr"
        }],
      links: {
        "en": [
          { title: "Contact us", href: "mailto:popword20@gmail.com" },

          { title: "Features", href: "#features" },
          { title: "Blog", href: "https://pop-word.com/articles" },
          { title: "about PoPWord", href: "https://pop-word.com/articles/about-popword" },
          { title: "FAQ", href: "https://pop-word.com/articles/FAQ" },
          { title: "Vocab Lobby", href: "https://pop-word.com/ui/#/Login" },
          { title: "Privacy Policy", href: "https://pop-word.com/privacyPolicy" },
          { title: "Terms of Use", href: "https://pop-word.com/termsOfUse" }
        ],
        "fr": [
          { title: "Contactez nous", href: "mailto:popword20@gmail.com" },

          { title: "Caracteristiques", href: "#features" },
          { title: "Blog", href: "https://pop-word.com/articles" },
          { title: "A propos de PoPWord", href: "https://pop-word.com/articles/about-popword" },
          { title: "FAQ", href: "https://pop-word.com/articles/FAQ" },
          { title: "Vocab Lobby", href: "https://pop-word.com/ui/#/Login" },
          { title: "Politique de confidentialité", href: "https://pop-word.com/privacyPolicy" }
        ],
        "es": [
          { title: "Contacta con nosotros", href: "mailto:popword20@gmail.com" },

          { title: "Características", href: "#features" },
          { title: "Blog", href: "https://pop-word.com/articles" },
          { title: "Sobre PoPWord", href: "https://pop-word.com/articles/about-popword" },
          { title: "FAQ", href: "https://pop-word.com/articles/FAQ" },
          { title: "Vocab Lobby", href: "https://pop-word.com/ui/#/Login" },
          { title: "Política de privacidad", href: "https://pop-word.com/privacyPolicy" }
        ],
        "de": [
          { title: "Kontaktiere uns", href: "mailto:popword20@gmail.com" },

          { title: "Merkmale", href: "#features" },
          { title: "Blog", href: "https://pop-word.com/articles" },
          { title: "über PoPWord", href: "https://pop-word.com/articles/about-popword" },
          { title: "FAQ", href: "https://pop-word.com/articles/FAQ" },
          { title: "Vocab Lobby", href: "https://pop-word.com/ui/#/Login" },
          { title: "Datenschutz-Bestimmungen", href: "https://pop-word.com/privacyPolicy" }
        ]
      },
      e1: 1,
      landing_page_url: ""


    };
  },
  methods: {
    reloadWebsite(e) {
      //window.location.href = `https://www.pop-word.com/#/${e}`;
      //document.location.assign(`http://localhost:3000/#/${e}`);
      document.location.assign(`${this.landing_page_url}/#/${e}`);
      document.location.reload();


    }
  },
  watch: {},
  mounted() {
    console.log("website language == ", this.selected_website_language, this.$route.params.region);
    this.landing_page_url = document.location.href.split("/#/")[0];
    this.selected_website_language = this.website_languages_abbr.indexOf(this.$route.params.region) > -1 ? this.$route.params.region : "en";

    if (this.selected_website_language == "en")
      this.selected_language = "fr";
    else
      this.selected_language = "en";
  }
}
;
</script>

<style scoped>
.bordered {
  border: 1px solid black !important;
}


.feat {
  margin: 0 !important;

}

.bordered-top {
  border-top: 1px solid black !important;
}

.bordered-bottom {
  border-bottom: 1px solid black !important;
}

.bordered-left {
  border-left: 1px solid black !important;
}

.bordered-right {
  border-right: 1px solid black !important;
}

.no-top-border {
  border-top: none !important;
}

.no-bottom-border {
  border-bottom: none !important;
}

.no-right-border {
  border-right: none !important;
}

.no-left-border {
  border-left: none !important;
}

.custom_background {
  background: #f0f0f0 !important;
}

.parent {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.img1 {
  grid-area: 1 / 1 / 7 / 10;
}

.img2 {
  grid-area: 2 / 6 / 9 / 13;
  z-index: 0;
}

.demoSelect {
  font-size: 0.75rem !important;
}

.demoSelect .v-label {
  font-size: 0.75rem !important;
}

::-webkit-scrollbar-thumb {
  background-color: #00a99e80;
}


::-webkit-scrollbar {
  width: 6px;
  background-color: #00d69e10;
}

.demo_banner {
  width: 100vw;
}

.demo_banner::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  /*background-color: #00d69e10;*/
  background-color: #4db6ac;
}

.demo_banner::-webkit-scrollbar-thumb {
  background-color: #004d40;
  /*background-color: white;*/
}

.langBtn:not(.selectedLanguage):hover {
  border-bottom: solid black;
}

.selectedLanguage {
  border-bottom: solid black !important;
  color: black
}
</style>
