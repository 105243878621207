<template>
  <div>
<!--    <div v-if="$auth.isLoading" class="hv_center">-->
<!--      <v-progress-circular-->
<!--        size="50"-->
<!--        color="#009688"-->
<!--        indeterminate-->
<!--      ></v-progress-circular>-->
<!--    </div>-->
    <v-app style="background:#f0f0f0;">
<!--      <app-header v-if="$auth.isAuthenticated" />-->
      <v-main>
        <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<script>
//import Header from "./components/Header.vue";

export default {
  name: "App",

  data: () => ({
    //
  }),
  components: {
    //"app-header": Header,
  },
};
</script>
<style>
.hv_center{
  position: absolute;
  top: 50%;
  left: 50%;
}

html {
  scroll-behavior: smooth;
}

</style>
