import "@fortawesome/fontawesome-free/css/all.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: "fa", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  options: {
    customProperties: true,
  },
  theme: {
    themes: {

      // light: {
      //   primary: colors.teal.base,
      //   secondary: colors.teal.lighten1,
      //   accent: "rgba(71,161,138,0.06)",
      //   dark: colors.teal.darken4,
      // },
      light: {
        primary: colors.grey.darken4,
        secondary: colors.grey.lighten1,
        accent: colors.grey.accent,
        dark: colors.grey.darken4,
        background: "#f0f0f0"
      },
    },
  },
});
