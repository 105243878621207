import Vue from "vue";
import VueRouter from "vue-router";
//import Home from "../views/Home.vue";
// import VocabLobby from "../views/VocabLobby2";
// import Stats from "../views/Stats";
// import Dashboard from "../views/Dashboard";
// import Login from "../views/Login";
// import { authenticationGuard } from "@/auth/authenticationGuard";
import LandingPage from "@/views/LandingPage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:region",
    name: "LandingPage",
    component: LandingPage,
  },{
    path: "/",
    name: "LandingPage",
    component: LandingPage,
  },
  // {
  //   path: "/Login",
  //   name: "Login",
  //   component: Login,
  // },
  // {
  //   path: "/",
  //   name: "VocabLobby",
  //   component: VocabLobby,
  //   beforeEnter: authenticationGuard,
  // },
  // {
  //   path: "/Stats",
  //   name: "Stats",
  //   component: Stats,
  //   beforeEnter: authenticationGuard,
  // },
  // {
  //   path: "/Dashboard",
  //   name: "Dashboard",
  //   component: Dashboard,
  //   beforeEnter: authenticationGuard,
  // },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
